<template>
  <v-menu
    v-model="menu"
    content-class="mt-2"
    :close-on-content-click="false"
    offset-y
    :min-width="$vuetify.breakpoint.mdAndUp ? '50vw' : '100%'"
    :width="$vuetify.breakpoint.mdAndUp ? '50vw' : '100%'"
    :max-width="$vuetify.breakpoint.mdAndUp ? '50vw' : '100%'"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="text-none gray3--text elevation-1"
        :class="_appliedCount ? 'primary--text' : 'gray3--text'"
        :disabled="!filters.length"
      >
        <v-icon left>mdi-filter-variant</v-icon>

        Filtrar <span v-if="_appliedCount">({{ _appliedCount }})</span>

        <v-icon v-if="filters.length" right>
          {{ menu ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </v-btn>
    </template>

    <v-card class="pa-6 custom-card">
      <div class="text-h5 font-weight-bold mb-2">Filtros</div>

      <div class="d-flex flex-column">
        <v-row
          v-for="(item, i) in appliedFilters"
          :key="i"
          class="my-2"
          align="center"
          no-gutters
        >
          <v-col cols="12" lg="5">
            <div class="d-flex align-center py-1 pr-md-3">
              <div class="text-body-1 font-weight-regular mr-2">Onde</div>

              <v-select
                v-model="item.filter"
                placeholder="Selecionar filtro"
                :items="filters"
                item-text="label"
                hide-details="auto"
                return-object
                outlined
                dense
              />
            </div>
          </v-col>

          <v-col cols="12" lg="7">
            <div class="d-flex align-center fill-width py-1">
              <div class="text-body-1 font-weight-regular mr-2">
                {{ item.filter?.multiple ? "são" : "é" }}
              </div>

              <div v-if="item.filter" class="d-flex fill-width">
                <v-autocomplete
                  v-if="item.filter.type === 'select'"
                  v-model="item.value"
                  placeholder="Selecionar opção"
                  item-text="label"
                  item-value="value"
                  hide-details="auto"
                  :items="item.filter.items"
                  :multiple="item.filter.multiple"
                  outlined
                  dense
                  @focus="focus()"
                  @blur="blur()"
                  @change="applyFilters()"
                >
                  <template
                    v-if="item.filter.multiple"
                    v-slot:selection="{ attrs, item: selectedItem, index }"
                  >
                    <div v-if="index === 0 && !focused" v-bind="attrs">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" small>
                            <span class="truncked">
                              {{ selectedItem.label }}
                            </span>
                          </v-chip>
                        </template>

                        <span>
                          {{ selectedItem.label }}
                        </span>
                      </v-tooltip>

                      <v-tooltip
                        v-if="item.value.length > 1"
                        max-width="20rem"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" small>
                            +{{ item.value.length - 1 }}
                          </v-chip>
                        </template>

                        <span
                          v-html="
                            item.value
                              .slice(1)
                              .map(
                                (el) =>
                                  item.filter.items.find(
                                    (filter) => filter.value === el
                                  ).label
                              )
                              .join(', ')
                          "
                        />
                      </v-tooltip>
                    </div>
                  </template>
                </v-autocomplete>

                <v-text-field
                  v-else
                  v-model="item.value"
                  placeholder="Digite para buscar"
                  hide-details="auto"
                  outlined
                  dense
                  @blur="applyFilters()"
                />
              </div>

              <v-text-field
                v-else
                placeholder="Selecione uma opção"
                hide-details="auto"
                outlined
                disabled
                dense
              />

              <v-btn
                class="ml-2"
                :disabled="!item.filter && !item.value"
                small
                icon
                @click="removeFilter(i)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <div :class="{ 'mt-4': appliedFilters.length }">
          <v-btn
            class="text-none"
            color="primary"
            depressed
            small
            @click.stop.prevent="addFilter()"
          >
            <v-icon left>mdi-plus</v-icon>
            Adicionar filtro
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      menu: false,
      focused: false,
      appliedFilters: [],
      appliedObject: {},
    };
  },

  props: {
    filters: {
      type: Array,
      default: () => [],
      /**
       * @property {String} label - label of input
       * @property {String} key - key of filter
       * @property {Array} items - optional: array of select options
       * * @property {String} label - label of item selection
       * * @property {String} value - value of item selection
       **/
    },
  },

  computed: {
    _appliedCount() {
      return Object.keys(this.appliedObject).length;
    },
  },

  methods: {
    applyFilters() {
      const filters = this.appliedFilters
        .filter((el) => el.filter)
        .map((el) => ({
          key: el.filter.key,
          value: el.value,
        }));

      const payload = filters.reduce((obj, item) => {
        obj[item.key] = item.value;
        return obj;
      }, {});

      this.appliedObject = structuredClone(payload);

      this.$emit("apply", payload);
    },

    addFilter() {
      this.appliedFilters.push({ filter: null, value: null });
    },

    removeFilter(index) {
      this.appliedFilters.splice(index, 1);
      this.applyFilters();
    },

    focus() {
      this.focused = true;
    },

    blur() {
      this.focused = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
