<template>
  <v-container>
    <div class="text-h5 font-weight-bold">Calendário de funcionamento</div>

    <section v-if="!loading">
      <div v-if="openingHours" class="d-flex align-center">
        <v-chip>
          Das {{ formatTime(openingHours.start.dateTime) }} às
          {{ formatTime(openingHours.end.dateTime) }}
        </v-chip>

        <v-btn
          class="ml-1"
          color="red"
          icon
          small
          @click="handleRemoveEvent(openingHours.id)"
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </div>

      <div v-else class="red--text text-body-2 font-weight-bold">
        Horário de funcionamento não definido
      </div>
    </section>

    <v-divider class="my-4" />

    <div v-if="loading" class="d-flex align-center justify-center py-6">
      <v-progress-circular indeterminate color="primary" />
    </div>

    <div v-else>
      <div
        class="d-flex flex-column flex-md-row align-end align-md-center mb-6"
      >
        <v-spacer />

        <v-btn
          v-if="$can('calendars.import')"
          class="text-none gray3--text mr-md-4 mb-2 mb-md-0"
          @click="handleImport()"
        >
          <v-icon left>mdi-calendar-import</v-icon>
          Importar
        </v-btn>

        <v-btn
          v-if="$can('calendars.events.create')"
          class="text-none gray3--text mr-md-4 mb-2 mb-md-0"
          @click="
            handleOpeningHoursDialog(
              openingHours,
              openingHours ? 'edit' : 'create'
            )
          "
        >
          <v-icon left>mdi-cog</v-icon>
          Horário de funcionamento
        </v-btn>

        <v-btn
          v-if="$can('calendars.events.create')"
          class="text-none gray3--text"
          @click="handleEventDialog()"
        >
          <v-icon left>mdi-plus</v-icon>
          Adicionar evento extraordinário
        </v-btn>
      </div>

      <v-card class="pa-4 rounded-lg mb-8 custom-card">
        <v-responsive :aspect-ratio="16 / 9">
          <iframe
            :src="`https://calendar.google.com/calendar/embed?src=${calendar.id}&ctz=${calendar.timeZone}`"
            style="border: 0"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </v-responsive>
      </v-card>

      <section v-if="events.length">
        <div class="text-h6 font-weight-bold mb-4">
          Eventos extraordinários adicionados
        </div>

        <div class="text-body-2 font-weight-bold gray1--text mb-4">
          Mostrando {{ events.length }} eventos extraordinários
        </div>

        <v-list class="pa-0 transparent" nav>
          <v-list-item
            v-for="(item, i) in events"
            class="rounded-lg white"
            :key="i"
          >
            <v-list-item-icon class="mr-n2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mt-2"
                    :color="
                      item.transparency === 'transparent' ? 'green' : 'red'
                    "
                    small
                  >
                    mdi-circle
                  </v-icon>
                </template>

                <span>
                  {{
                    item.transparency === "transparent"
                      ? "Disponível"
                      : "Inisponível"
                  }}
                </span>
              </v-tooltip>
            </v-list-item-icon>

            <v-list-item-content class="pl-2">
              <v-list-item-title>
                {{ item.summary }}
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ item.description }}</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-action class="mr-3">
              <v-chip
                :color="
                  handleHighlightDate(item.start, item.end)
                    ? 'amber lighten-4'
                    : 'grey lighten-3'
                "
                small
              >
                <span
                  v-if="
                    (formatDate(
                      item.start.date || item.start.dateTime,
                      'YYYY-MM-DD'
                    ) === _today &&
                      formatDate(
                        item.end.date || item.end.dateTime,
                        'YYYY-MM-DD'
                      )) === _today
                  "
                  class="font-weight-regular black--text"
                >
                  Hoje
                </span>

                <span v-else>
                  <span v-if="item.start.date">
                    {{ formatDate(item.start.date, "DD/MM/YYYY") }}
                    <span v-if="item.start.date !== item.end.date">
                      - {{ formatDate(item.end.date, "DD/MM/YYYY") }}
                    </span>
                  </span>

                  <span v-else>
                    {{ formatDate(item.start.dateTime, "DD/MM/YYYY") }}
                    <span v-if="item.start.dateTime !== item.end.dateTime">
                      - {{ formatDate(item.end.dateTime, "DD/MM/YYYY") }}
                    </span>
                  </span>
                </span>

                <span v-if="item.start.dateTime && item.end.dateTime">
                  , {{ formatDate(item.start.dateTime, "HH:mm") }} às
                  {{ formatDate(item.end.dateTime, "HH:mm") }}
                </span>
              </v-chip>
            </v-list-item-action>

            <v-menu content-class="mt-2" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !$can('calendars.events.edit') &&
                    !$can('calendars.events.delete')
                  "
                  small
                  icon
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list min-width="12.5rem">
                <v-list-item
                  v-if="$can('calendars.events.edit')"
                  @click="handleEventDialog(item, 'edit')"
                >
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="$can('calendars.events.delete')"
                  @click="handleRemoveEvent(item.id)"
                >
                  <v-list-item-title>Excluir</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-list>
      </section>
    </div>

    <ImportDialog
      ref="importDialog"
      :calendar="calendar"
      @success="getData()"
    />

    <EventDialog ref="eventDialog" :calendar="calendar" @success="getData()" />

    <OpeningHoursDialog
      ref="openingHoursDialog"
      :calendar="calendar"
      @success="getData()"
    />

    <DeleteEvent ref="deleteEvent" :calendar="calendar" @success="getData()" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {
  getCalendar,
  getCalendarEvents,
  getCalendarEvent,
} from "@/services/calendars";

import { handleAlert, formatDate } from "@/utils";
import ImportDialog from "./partials/importDialog/ImportDialog";
import EventDialog from "./partials/eventDialog/EventDialog";
import OpeningHoursDialog from "./partials/openingHoursDialog/OpeningHoursDialog";
import DeleteEvent from "./partials/deleteEvent/DeleteEvent";
const moment = require("moment");

export default {
  name: "Calendar",

  components: {
    ImportDialog,
    EventDialog,
    OpeningHoursDialog,
    DeleteEvent,
  },

  data() {
    return {
      loading: true,
      calendar: null,
      openingHours: null,
      events: [],
    };
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    ...mapState(["user"]),

    _today() {
      return moment().format("YYYY-MM-DD");
    },
  },

  methods: {
    async getData() {
      this.loading = true;

      await Promise.all([this.getCalendar(), this.getCalendarEvents()]);

      this.loading = false;
    },

    async getCalendar() {
      try {
        const { data } = await getCalendar(this.$route.params.calendarID);
        this.calendar = data;
      } catch (error) {
        const message =
          error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          "Erro ao buscar eventos";
        this.handleAlert(message, "error");
      }
    },

    async getCalendarEvents() {
      try {
        const { data } = await getCalendarEvents(this.$route.params.calendarID);

        this.events = data.items.filter(
          (el) => el.summary !== "Horário de funcionamento"
        );

        const recurrenceEventChild = data.items.find(
          (el) => el.summary === "Horário de funcionamento"
        );

        if (recurrenceEventChild) {
          const { recurringEventId } = recurrenceEventChild;
          const { data: recurrenceEvent } = await getCalendarEvent(
            this.$route.params.calendarID,
            recurringEventId
          );
          this.openingHours = recurrenceEvent;
        } else {
          this.openingHours = null;
        }
      } catch (error) {
        const message =
          error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          "Erro ao buscar eventos";
        this.handleAlert(message, "error");
      }
    },

    formatTime(value) {
      return moment(value).format("HH:mm");
    },

    handleImport() {
      this.$refs.importDialog.openDialog();
    },

    handleEventDialog(event, mode) {
      this.$refs.eventDialog.openDialog(event, mode);
    },

    handleOpeningHoursDialog(event, mode) {
      this.$refs.openingHoursDialog.openDialog(event, mode);
    },

    handleRemoveEvent(event) {
      this.$refs.deleteEvent.open(event);
    },

    handleHighlightDate(start, end) {
      const startDate = formatDate(start.date || start.dateTime, "YYYY-MM-DD");
      const endDate = formatDate(end.date || end.dateTime, "YYYY-MM-DD");

      return this._today >= startDate && this._today <= endDate;
    },

    handleAlert,

    formatDate,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
