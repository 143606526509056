<template>
  <CustomDialog
    ref="customDialog"
    :title="_title"
    :subtitle="_subtitle"
    :footerButtons="_buttons"
    :loading="loading"
    @submit="submit()"
  >
    <v-form v-model="valid" ref="form" class="mt-4" :disabled="loading">
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            v-model="form.email"
            label="E-mail"
            :disabled="_editMode || _viewMode"
            :rules="_rules"
            outlined
          />
        </v-col>

        <v-col v-if="_canAttendances" cols="12">
          <div class="mb-2 text-body-2 font-weight-bold">
            Módulo de atendimento
          </div>

          <v-select
            v-model="form.attendanceGroups"
            item-text="name"
            item-value="_id"
            label="Função"
            :items="attendanceGroups"
            :disabled="_viewMode"
            :rules="_attendanceRules"
            clearable
            outlined
            @change="$refs.form.resetValidation()"
          />
        </v-col>

        <v-col v-if="_canAudiences" cols="12">
          <div class="mb-2 text-body-2 font-weight-bold">
            Módulo de audiência
          </div>

          <v-select
            v-model="form.audienceGroups"
            item-text="name"
            item-value="_id"
            label="Função"
            :items="audienceGroups"
            :disabled="_viewMode"
            :rules="_audienceRules"
            clearable
            outlined
            @change="$refs.form.resetValidation()"
          />
        </v-col>
      </v-row>
    </v-form>
  </CustomDialog>
</template>

<script>
import { createUser, updateUser } from "@/services/users";
import { getPermissionGroups } from "@/services/permissions";
import { handleAlert } from "@/utils";
import { validation } from "@/utils/validators";
import { mapGetters, mapState } from "vuex";

import CustomDialog from "@/components/customDialog/CustomDialog.vue";

export default {
  name: "UserDialog",

  components: {
    CustomDialog,
  },

  data() {
    return {
      mode: "create",
      valid: false,
      loading: false,
      attendanceGroups: [],
      audienceGroups: [],
      backupGroups: [],
      form: {
        _id: "",
        email: "",
        type: "",
        attendanceGroups: "",
        audienceGroups: "",
      },
    };
  },

  computed: {
    ...mapState(["user"]),
    ...mapGetters(["attendanceRole"]),

    _viewMode() {
      return this.mode === "view";
    },

    _editMode() {
      return this.mode === "edit";
    },

    _title() {
      if (this._viewMode) return "Visualizar Usuário";
      else if (this._editMode) return "Editar Usuário";

      return "Adicionar Usuário";
    },

    _subtitle() {
      if (this._viewMode) return "";
      if (this._editMode)
        return "Preencha os campos abaixo para editar usuário";

      return "Preencha os campos abaixo para cadastrar usuário";
    },

    _buttons() {
      if (this._viewMode)
        return [
          {
            label: "Fechar",
            color: "primary",
            action: "close",
          },
        ];

      return [
        {
          label: "Cancelar",
          color: "secondary",
          action: "close",
        },
        {
          label: "Prosseguir",
          color: "primary",
          action: "submit",
        },
      ];
    },

    _rules() {
      return [
        (v) => (!!v && v.length > 0) || "Campo obrigatório",
        (v) => validation(v) || "Informe um valor válido",
      ];
    },

    _attendanceRules() {
      return [
        (v) => (!this.form.audienceGroups ? !!v : true) || "Campo obrigatório",
      ];
    },

    _audienceRules() {
      return [
        (v) =>
          (!this.form.attendanceGroups ? !!v : true) || "Campo obrigatório",
      ];
    },

    _canPeoplePermissions() {
      return this.$can("people.edit.permissions");
    },

    _canAttendances() {
      return (
        this._canPeoplePermissions &&
        this.$can("attendances") &&
        this.attendanceRole !== "operator"
      );
    },

    _canAudiences() {
      return this._canPeoplePermissions && this.$can("audiences");
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;

      if (this._editMode) await this.updateUser();
      else await this.createUser();

      this.loading = false;
    },

    getPersonType(permissionGroupID) {
      if (!permissionGroupID) {
        return null;
      }

      const permissionGroup = this.attendanceGroups.find(
        ({ _id }) => _id === permissionGroupID
      );
      if (permissionGroup?.role === "admin") return "director";
      if (permissionGroup?.role === "moderator") return "coordinator";
      if (permissionGroup?.role === "operator") return "attendant";
      return null;
    },

    async createUser() {
      try {
        const type =
          this.form.type || this.getPersonType(this.form.attendanceGroups);

        const payload = {
          ...(!!type && { type }),
          email: this.form.email.toLowerCase(),
          ...(this._canPeoplePermissions && {
            permissionGroups: [
              ...(this.form.attendanceGroups
                ? [
                    {
                      _id: this.form.attendanceGroups,
                    },
                  ]
                : []),
              ...(this.form.audienceGroups
                ? [
                    {
                      _id: this.form.audienceGroups,
                    },
                  ]
                : []),
            ],
          }),
        };

        await createUser(payload);

        this.handleAlert("Usuário criado com sucesso", "success");

        this.$emit("success");
        this.closeDialog();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async updateUser() {
      try {
        const type = this.getPersonType(this.form.attendanceGroups);

        const payload = {
          ...(!!type && { type }),
          ...(this._canPeoplePermissions && {
            permissionGroups: [
              ...this.backupGroups.map((el) => ({
                _id: el._id,
              })),
              ...(this.form.attendanceGroups
                ? [
                    {
                      _id: this.form.attendanceGroups,
                    },
                  ]
                : []),
              ...(this.form.audienceGroups
                ? [
                    {
                      _id: this.form.audienceGroups,
                    },
                  ]
                : []),
            ],
          }),
        };

        await updateUser(this.form._id, payload);

        this.handleAlert("Usuário editado com sucesso", "success");

        this.$emit("success");
        this.closeDialog();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async handlePermissionGroups() {
      await Promise.all([
        this.getAttendancePermissionGroups(),
        this.getAudiencePermissionGroups(),
      ]);
    },

    async getAttendancePermissionGroups() {
      if (!this._canAttendances) return;

      try {
        const payload = {
          module: "attendances",
          type: "role",
        };

        const { data } = await getPermissionGroups(payload);

        this.attendanceGroups =
          this.attendanceRole === "admin"
            ? data
            : data.filter((el) => el.role !== "admin");
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async getAudiencePermissionGroups() {
      if (!this._canAudiences) return;

      try {
        const payload = {
          module: "audiences",
          type: "role",
        };

        const { data } = await getPermissionGroups(payload);

        this.audienceGroups = data;
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async openDialog(data, mode) {
      this.mode = mode || "create";

      await this.handlePermissionGroups();

      if (data) {
        let attendanceEntities = [];
        let audienceEntities = [];

        if (this._canAttendances || this._canAudiences) {
          this.backupGroups = data.permissionGroups.filter(
            (el) =>
              !this.attendanceGroups.find((perm) => perm._id === el._id) &&
              !this.audienceGroups.find((perm) => perm._id === el._id)
          );

          if (this._canAttendances)
            attendanceEntities = data.permissionGroups.filter(
              (el) =>
                !!this.attendanceGroups.find((perm) => perm._id === el._id)
            );

          if (this._canAudiences)
            audienceEntities = data.permissionGroups.filter(
              (el) => !!this.audienceGroups.find((perm) => perm._id === el._id)
            );
        }

        this.form = {
          _id: data._id,
          email: data.email,
          ...(data.type && { type: data.type }),
          attendanceGroups: attendanceEntities.length
            ? attendanceEntities[0]._id
            : "",
          audienceGroups: audienceEntities.length
            ? audienceEntities[0]._id
            : "",
        };
      } else {
        this.resetForm();
      }

      this.$refs.customDialog.open();
    },

    closeDialog() {
      this.resetForm();
      this.$refs.customDialog.close();
    },

    resetForm() {
      this.form._id = "";
      this.form.email = "";
      this.form.type = "";

      if (this.$refs.form) this.$refs.form.resetValidation();
    },

    handleAlert,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
