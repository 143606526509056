<template>
  <CustomDialog
    ref="customDialog"
    title="Remover usuário"
    :loading="loading"
    @submit="submit()"
  >
    <div class="text-body-1">
      Conclua a operação para remover o usuário selecionado.
    </div>
  </CustomDialog>
</template>

<script>
import CustomDialog from "@/components/customDialog/CustomDialog.vue";
import { deleteUser } from "@/services/users";
import { handleAlert } from "@/utils";

export default {
  components: {
    CustomDialog,
  },

  data() {
    return {
      loading: false,
      userID: "",
    };
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        await deleteUser(this.userID);

        this.handleAlert("Usuário removido com sucesso", "success");
        this.$emit("success");
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.close();
        this.loading = false;
      }
    },

    open(userID) {
      this.userID = userID;
      this.$refs.customDialog.open();
    },

    close() {
      this.$refs.customDialog.close();
    },

    handleAlert,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
