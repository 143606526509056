import { getSocket, connectSocket } from "./index";
import { eventBus } from "@/eventBus.js";

function initializeListeners(socket) {
  socket.on("entities:queue:updated", (data) => {
    eventBus.$emit("entities:queue:updated", data);
  });
}

// Chamada para garantir que os listeners sejam inicializados quando o socket estiver conectado
export function ensureEntitiesSocketListeners() {
  const socket = getSocket();
  if (socket) {
    initializeListeners(socket);
  } else {
    console.warn("Socket ainda não está conectado. Tentando reconectar...");
    const connectedSocket = connectSocket();
    if (connectedSocket) {
      initializeListeners(connectedSocket);
    }
  }
}

export function joinEntitiesQueue({ data, callback }) {
  const socket = getSocket();

  if (socket) {
    return socket.emit("entities:queue:join", data, callback);
  } else {
    console.error("Socket não está conectado.");
  }
}

export function leaveEntitiesQueue({ data, callback }) {
  const socket = getSocket();

  if (socket) {
    return socket.emit("entities:queue:leave", data, callback);
  } else {
    console.error("Socket não está conectado.");
  }
}
