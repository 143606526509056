<template>
  <router-view v-if="!loading" />
</template>

<script>
export default {
  name: "Attendance",

  data() {
    return {
      loading: true,
    };
  },

  created() {
    this.loading = false;
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
