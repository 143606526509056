<template>
  <CustomDialog
    ref="customDialog"
    :title="_title"
    :subtitle="_subtitle"
    :footerButtons="_buttons"
    :loading="loading"
    @submit="submit()"
  >
    <v-form v-model="valid" ref="form" class="mt-4">
      <v-text-field
        v-model="form.sourceCalendarID"
        label="ID do calendário de origem"
        :disabled="loading"
        :rules="_rules"
        outlined
      />

      <v-select
        v-model="form.transparency"
        label="Disponibilidade"
        :items="transparencies"
        :disabled="loading"
        :rules="_rules"
        item-text="text"
        item-value="value"
        outlined
      />
    </v-form>
  </CustomDialog>
</template>

<script>
import { importCalendar } from "@/services/calendars";
import { handleAlert } from "@/utils";
import { validation } from "@/utils/validators";
import CustomDialog from "@/components/customDialog/CustomDialog.vue";
const moment = require("moment");

export default {
  name: "ImportDialog",

  components: {
    CustomDialog,
  },

  data() {
    return {
      valid: false,
      loading: false,
      transparencies: [
        {
          text: "Disponível",
          value: "transparent",
        },
        {
          text: "Indisponível",
          value: "opaque",
        },
      ],
      form: {
        id: "",
        sourceCalendarID: "",
        transparency: "",
      },
    };
  },

  props: {
    calendar: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    _title() {
      return "Importar calendário";
    },

    _subtitle() {
      return "Informe o ID do calendário de origem para a importação";
    },

    _buttons() {
      return [
        {
          label: "Cancelar",
          color: "secondary",
          action: "close",
        },
        {
          label: "Prosseguir",
          color: "primary",
          action: "submit",
        },
      ];
    },

    _rules() {
      return [
        (v) => !!v || "Campo obrigatório",
        (v) => validation(v) || "Informe um valor válido",
      ];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          calendarID: this.form.sourceCalendarID,
          transparency: this.form.transparency,
          timeMax: moment().endOf("year").toISOString(),
        };

        await importCalendar(this.calendar.id, payload);

        this.handleAlert("Calendário importado com sucesso", "success");

        this.$emit("success");
        this.closeDialog();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    openDialog() {
      this.resetForm();
      this.$refs.customDialog.open();
    },

    closeDialog() {
      this.resetForm();
      this.$refs.customDialog.close();
    },

    resetForm() {
      this.form.sourceCalendarID = "";
      this.form.transparency = "";

      if (this.$refs.form) this.$refs.form.resetValidation();
    },

    handleAlert,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
