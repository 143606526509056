<template>
  <v-container>
    <div class="d-flex mb-1">
      <div class="text-h5 font-weight-bold">Usuários</div>

      <v-spacer />

      <v-btn
        v-if="$can('people.import')"
        class="text-none gray3--text"
        @click="handleImportUsersDialog()"
      >
        <v-icon left>mdi-import</v-icon>
        Importar
      </v-btn>
    </div>

    <TabNavigation
      class="mb-4"
      :tabs="_tabs"
      @change="handleTabs($event)"
      v-model="tab"
    />

    <div class="d-flex align-center mb-4">
      <FilterSelector :filters="_filterOptions" @apply="handleFilter($event)" />

      <v-spacer />

      <div class="search-content">
        <v-text-field
          v-model="search"
          placeholder="Pesquisar Usuário"
          class="white rounded-lg mr-4"
          append-icon="mdi-magnify"
          hide-details
          outlined
          dense
          @keyup="handleSearch()"
        />
      </div>

      <v-btn
        v-if="$can('people.create')"
        class="text-none gray3--text"
        @click="handleUserDialog()"
      >
        <v-icon left>mdi-plus</v-icon>
        Adicionar
      </v-btn>
    </div>

    <div
      v-if="users.length > 0"
      class="text-body-2 font-weight-bold gray1--text mb-6"
    >
      Mostrando {{ users.length }} {{ users.length > 1 ? "pessoas" : "pessoa" }}
    </div>

    <div v-else class="text-body-2 font-weight-bold gray1--text mb-6">
      Não existem resultados
    </div>

    <CustomTable
      ref="customTable"
      :items="users"
      :headers="_headers"
      :offset="pagination.offset"
      :limit="pagination.limit"
      :total="pagination.total"
      :loading="loading"
      :disableSort="true"
      @change:pagination="handlePagination($event)"
      @delete="handleDeleteUser($event)"
      @edit="handleUserDialog($event, 'edit')"
      @view="handleUserDialog($event, 'view')"
    />

    <user-dialog ref="modal" @success="getUsers()" />

    <DeleteUser ref="deleteUser" @success="getUsers()" />

    <ImportUsersDialog ref="importUsers" @success="getUsers()" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { getUsers } from "@/services/users";
import { getPermissionGroups } from "@/services/permissions";
import TabNavigation from "@/components/tabNavigation/TabNavigation.vue";
import CustomTable from "@/components/customTable/CustomTable.vue";
import FilterSelector from "@/components/filterSelector/FilterSelector";
import DeleteUser from "./partials/deleteUser/DeleteUser.vue";
import UserDialog from "./partials/userDialog/UserDialog.vue";
import ImportUsersDialog from "./partials/importUsersDialog/ImportUsersDialog.vue";

export default {
  name: "Users",

  components: {
    TabNavigation,
    CustomTable,
    FilterSelector,
    DeleteUser,
    UserDialog,
    ImportUsersDialog,
  },

  data() {
    return {
      tab: 0,
      interval: null,
      loading: true,
      loadingTabs: true,
      type: "",
      permissionGroup: "",
      typeGroup: "",
      search: "",
      filters: {},
      pagination: {
        limit: 15,
        offset: 0,
        total: 0,
      },
      users: [],
      audienceGroups: [],
      attendanceGroups: [],
    };
  },

  beforeMount() {
    this.getData();
  },

  created() {
    const tabIndex = this._tabs.findIndex(
      (el) => el.value === this.$route.name
    );

    if (tabIndex > -1) {
      this.tab = this._tabs.findIndex((el) => el.value === this.$route.name);
    }
  },

  computed: {
    ...mapState(["user"]),
    ...mapGetters(["attendanceRole"]),

    _tabs() {
      const tabs = [
        {
          label: "Geral",
          value: "",
        },
        ...(!this.loadingTabs
          ? [
              ...this.attendanceGroups.map((el) => ({
                label: el.name,
                value: el._id,
                type: "attendances",
              })),
              ...this.audienceGroups.map((el) => ({
                label: el.name,
                value: el._id,
                type: "audiences",
              })),
            ]
          : [{ label: "Carregando...", value: "", disabled: true }]),
      ];

      return tabs;
    },

    _headers() {
      return [
        {
          text: "Nome",
          value: "name",
        },
        {
          text: "E-mail",
          value: "email",
        },
        ...(this.$can("attendances") &&
        this.attendanceRole !== "operator" &&
        (this.tab === 0 || this.typeGroup === "attendances")
          ? [
              {
                text: "Função (Atendimento)",
                value: "attendanceGroupLabel",
              },
              {
                text: "Associações (Atendimento)",
                value: "attendanceAssociations",
                type: "chip-group",
              },
            ]
          : []),
        ...(this.$can("audiences") &&
        (this.tab === 0 || this.typeGroup === "audiences")
          ? [
              {
                text: "Função (Audiência)",
                value: "audienceGroupLabel",
              },
              {
                text: "Associações (Audiência)",
                value: "audienceAssociations",
                type: "chip-group",
              },
            ]
          : []),
        {
          value: "actions",
          type: "actions",
          align: "center",
          width: "2.5rem",
        },
      ];
    },

    _filterOptions() {
      return [
        {
          label: "Nome",
          key: "name",
        },
        {
          label: "E-mail",
          key: "email",
        },
      ];
    },
  },

  methods: {
    async getData() {
      await this.handlePermissionGroups();
      await this.getUsers();
    },

    async getUsers() {
      try {
        this.loading = true;

        const payload = {
          limit: this.pagination.limit,
          offset: this.pagination.offset,
          ...(this.search && { search: this.search }),
          ...(this.permissionGroup && {
            permissionGroupIDs: [this.permissionGroup],
          }),
          ...(this.filters.name && { name: this.filters.name }),
          ...(this.filters.email && { email: this.filters.email }),
        };

        const { data, headers } = await getUsers(payload);

        this.processUsers(data);

        this.pagination.total = parseInt(headers["x-count"]);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    processUsers(data) {
      this.users = data.map((el) => {
        const audienceEntities = el.permissionGroups.filter(
          (elPerm) =>
            !!this.audienceGroups.find((perm) => perm._id === elPerm._id)
        );

        return {
          ...el,
          attendanceGroupLabel: this.handleGroupLabel(el.permissionGroups),
          audienceGroupLabel: audienceEntities.length
            ? audienceEntities[0].name
            : "",
          attendanceAssociations: this.handleAssociations(el),
          audienceAssociations: el.entities.map((el) => el.name),
          actions: [
            {
              value: "menu",
              icon: "mdi-dots-vertical",
              menu: [
                {
                  label: "Visualizar",
                  value: "view",
                },
                ...(this.$can("people.edit")
                  ? [
                      {
                        label: "Editar",
                        value: "edit",
                      },
                    ]
                  : []),
                ...(this.$can("people.delete")
                  ? [
                      {
                        label: "Remover",
                        value: "delete",
                      },
                    ]
                  : []),
              ],
            },
          ],
        };
      });
    },

    async handleDeleteUser(event) {
      this.$refs.deleteUser.open(event._id);
    },

    async handlePermissionGroups() {
      this.loadingTabs = true;

      await Promise.all([
        this.getAttendancePermissionGroups(),
        this.getAudiencePermissionGroups(),
      ]);

      this.loadingTabs = false;
    },

    async getAttendancePermissionGroups() {
      if (!this.$can("attendances") || this.attendanceRole === "operator")
        return;

      try {
        const payload = {
          module: "attendances",
          type: "role",
        };

        const { data } = await getPermissionGroups(payload);

        this.attendanceGroups = (
          this.attendanceRole === "admin"
            ? data
            : data.filter((el) => el.role !== "admin")
        ).reverse();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async getAudiencePermissionGroups() {
      if (!this.$can("audiences")) return;

      try {
        const payload = {
          module: "audiences",
          type: "role",
        };

        const { data } = await getPermissionGroups(payload);

        this.audienceGroups = data;
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    handleTabs(event) {
      this.permissionGroup = event.value || "";
      this.typeGroup = event.type || "";

      this.resetPagination();
      this.$refs.customTable.resetPagination();
      this.getUsers();
    },

    handleFilter(event) {
      this.filters = structuredClone(event);
      this.pagination.offset = 0;
      this.$refs.customTable.resetPagination();

      this.getUsers();
    },

    handleSearch() {
      if (this.interval) clearInterval(this.interval);

      this.interval = setTimeout(() => {
        this.resetPagination();
        this.$refs.customTable.resetPagination();

        this.getUsers();
      }, 500);
    },

    handlePagination(event) {
      this.pagination.offset = event.offset;
      this.getUsers();
    },

    handleGroupLabel(permissionGroups) {
      if (!permissionGroups?.length) return "";

      const result = permissionGroups.find(
        (group) => group.role && group.module === "attendances"
      );
      const role = result ? result.role : null;

      const types = {
        operator: "Atendente",
        moderator: "Gestor",
        admin: "Administrador",
      };

      return types[role] || "";
    },

    handleAssociations(user) {
      let result = [];

      if (
        user.permissionGroups.find(
          (el) => el.role === "operator" && el.module === "attendances"
        )
      )
        result = user.desks;
      if (
        user.permissionGroups.find(
          (el) => el.role === "moderator" && el.module === "attendances"
        )
      )
        result = user.subgroups;
      if (
        user.permissionGroups.find(
          (el) => el.role === "admin" && el.module === "attendances"
        )
      )
        result = user.groups;

      return result.map((el) => el.name);
    },

    resetPagination() {
      this.pagination.total = 0;
      this.pagination.limit = 15;
      this.pagination.offset = 0;
    },

    handleUserDialog(user, mode) {
      this.$refs.modal.openDialog(user, mode);
    },

    handleImportUsersDialog() {
      this.$refs.importUsers.openDialog();
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
