<template>
  <v-app v-if="loaded" id="app">
    <NavDrawer
      v-if="_authenticated"
      ref="navDrawer"
      @change-status="changeStatus($event)"
      @sign-out="signOut()"
      @notification-bell="playNotificationSound()"
    />
    <FilterDrawer
      v-if="_authenticated"
      ref="filterDrawer"
      v-model="filterDrawer"
    />
    <v-main class="background">
      <div class="d-flex flex-row justify-space-between">
        <v-app-bar-nav-icon
          v-if="_showNavIcon"
          class="ml-2 mt-2"
          @click.stop="handleDrawer()"
        />
        <v-tooltip bottom v-if="_isFilterableRouter">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-badge
                bottom
                left
                overlap
                offset-x="16"
                offset-y="16"
                :content="_filtersWithSelectedItems.length"
                :color="
                  _filtersWithSelectedItems.length ? 'primary' : 'transparent'
                "
              >
                <v-btn
                  class="mx-2 mt-2 grey"
                  icon
                  dark
                  @click="filterDrawer = !filterDrawer"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </v-badge>
            </div>
          </template>
          <span>{{ _filtersInfoText }}</span>
        </v-tooltip>
      </div>

      <router-view />
    </v-main>

    <v-dialog v-model="newAttendanceDialog" persistent :max-width="500">
      <v-card class="rounded-lg pa-2" elevation="3" width="500">
        <v-card-title class="my-auto">
          Nova solicitação de atendimento

          <v-spacer />

          <v-btn
            v-if="_isAdmin || _isModerator"
            icon
            @click="newAttendanceDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider class="mt-2" />

        <v-card-text class="text-center mt-4">
          <p class="mx-auto my-auto">Clique para iniciar o atendimento.</p>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            @click="handleStartAttendance"
            class="button-pulse"
          >
            <v-icon>mdi-phone-in-talk </v-icon>
            Atender
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <LoaderHover v-model="loadingOverlay" />
    <!-- floating button -->
    <v-btn
      v-if="_attendanceInProgress"
      class="button-pulse"
      fab
      dark
      fixed
      bottom
      right
      color="primary"
      @click="toAttendance()"
    >
      <v-icon>mdi-phone-in-talk</v-icon>
    </v-btn>
    <AlertBar ref="alertBar" />
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { loadSession, signOut } from "@/services/auth";
import { updateUser } from "@/services/users";
import { eventBus } from "@/eventBus.js";
import { Howl, Howler } from "howler";
import {
  attendanceJoin,
  startAttendance,
  pingEmit,
} from "@/socket/attendances";
import NavDrawer from "./components/navDrawer/NavDrawer";
import AlertBar from "./components/alertBar/AlertBar.vue";
import { getCounter, getCounters } from "./services/counters";
import { getCourt, getCourts } from "./services/courts";
import { getJurisdiction, getJurisdictions } from "./services/jurisdictions";
import FilterDrawer from "./components/filterDrawer/FilterDrawer.vue";
import {
  getAttendances,
  getAttendancesWaitingMe,
} from "./services/attendances";
import { connectSocket, disconnectSocket } from "./socket";

export default {
  name: "App",

  components: {
    NavDrawer,
    AlertBar,
    FilterDrawer,
  },

  data() {
    return {
      newAttendanceDialog: false,
      newAttendanceID: null,
      loaded: false,
      loadingOverlay: false,
      notificationSoundInterval: null,
      filterDrawer: false,
      filtersRoutes: [
        "Reports",
        "QueueAttendance",
        "TimeAttendance",
        "Satisfaction",
        "Quit",
        "QueueWaitingTime",
        // "Queue",
        // "Waiting",
        // "InProgress",
      ],
      interval: null,
    };
  },

  watch: {
    newAttendanceDialog(value) {
      if (value) {
        this.startNotificationSound();
      } else {
        this.stopNotificationSound();
      }
    },
    attendance(value, oldValue) {
      console.log("watch:attendance", {
        from: oldValue,
        to: value,
      });

      if (!value) {
        return;
      }

      const fromAttendanceID =
        oldValue?._id || oldValue?.id || oldValue?.attendanceID;
      const toAttendanceID = value?._id || value?.id || value?.attendanceID;

      const isDiff = fromAttendanceID !== toAttendanceID;
      if (!isDiff) {
        return console.log(
          "watch:attendance:not-diff",
          fromAttendanceID,
          toAttendanceID
        );
      }

      this.toAttendance();
    },
    authenticated(value, oldValue) {
      if (!value) {
        console.log("watch:authenticated -> cleanupSocketListeners");
        this.cleanupSocketListeners();
        clearInterval(this.interval);
        return;
      }

      // authenticating
      if (!oldValue && value) {
        console.log("watch:authenticated -> setupSocketListeners");
        this.setupSocketListeners();
        this.handlePingSession();
        return;
      }
    },
    refreshToken(newVal, oldVal) {
      this.handleChangeToken(newVal, oldVal);
    },
  },

  computed: {
    ...mapState(["user", "attendance", "filters"]),
    ...mapGetters(["authenticated", "refreshToken", "attendanceRole"]),

    _isOperator() {
      return this.attendanceRole === "operator";
    },

    _isModerator() {
      return this.attendanceRole === "moderator";
    },

    _isAdmin() {
      return this.attendanceRole === "admin";
    },

    _isAvailable() {
      if (!this._authenticated) {
        return false;
      }

      const user = this.user;
      if (!user) {
        return false;
      }

      // In Attendance
      if (this.attendance?.status === "in-progress") {
        console.log("watch:isAvailable:in-progress");
        return false;
      }

      if (user.status !== "online") {
        console.log("watch:isAvailable:offline");
        return false;
      }

      const isAdmin = this._isModerator || this._isAdmin;

      if (isAdmin) {
        const preference = user.settings?.attendancesNotification;
        console.log("watch:isAvailable:admin", !!preference);
        return !!preference;
      }

      console.log("watch:isAvailable:attendant", true);
      return true;
    },

    _authenticated() {
      return this.$store.getters.authenticated;
    },
    _attendanceInProgress() {
      return this.authenticated && this.attendance?.status === "in-progress";
    },
    _showNavIcon() {
      return (
        this._authenticated &&
        this.$route.name !== "Chat" &&
        this.$route.name !== "Details"
      );
    },
    _isFilterableRouter() {
      return this.filtersRoutes.includes(this.$route.name);
    },
    _filtersWithSelectedItems() {
      if (!this.filters) {
        return [];
      }

      return Object.keys(this.filters).filter((key) => {
        const filters = this.filters[key];
        return !!filters?.length > 0;
      });
    },
    _filtersInfoText() {
      const { length } = this._filtersWithSelectedItems || [];
      if (!length) {
        return "Nenhum filtro aplicado";
      }

      if (length === 1) {
        return `1 filtro aplicado`;
      }

      return `${length} filtros aplicados`;
    },
  },

  created() {
    this.handleSession();

    // Verificar o estado inicial e conectar/desconectar o socket conforme necessário
    if (this.authenticated) {
      console.log("Inicialmente autenticado. Conectando ao socket...");
      connectSocket();
      this.handlePingSession();
    } else {
      console.log(
        "Inicialmente não autenticado. Garantindo que o socket esteja desconectado..."
      );
      disconnectSocket();
      clearInterval(this.interval);
    }
  },

  mounted() {
    this.setupEventListeners();

    if (this.authenticated) {
      if (this.attendance) {
        if (this._attendanceInProgress) {
          this.handleJoinAttendance();
        }

        this.toAttendance();
      }

      return;
    }

    this.loadingOverlay = false;
  },

  beforeDestroy() {
    this.cleanupEventListeners();
    clearInterval(this.interval);
  },

  methods: {
    ...mapActions(["setUser", "setSignOut", "setAttendance"]),
    setupEventListeners() {
      this.$root.$on("alert", this.handleAlert);
      this.$root.$on("drawer", this.handleDrawer);
      this.$root.$on("load-session", this.handleSession);
      this.setupSocketListeners();
    },
    setupSocketListeners() {
      eventBus.$on("attendance:queue", this.onQueue);
      eventBus.$on("attendance:started", this.onStarted);
    },
    cleanupEventListeners() {
      this.$root.$off("alert", this.handleAlert);
      this.$root.$off("drawer", this.handleDrawer);
      this.$root.$off("load-session", this.handleSession);
      this.cleanupSocketListeners();
    },
    cleanupSocketListeners() {
      eventBus.$off("attendance:queue", this.onQueue);
      eventBus.$off("attendance:started", this.onStarted);
    },
    toAttendance() {
      const attendanceID =
        this.attendance?._id ||
        this.attendance?.id ||
        this.attendance?.attendanceID;

      this.loadingOverlay = false;

      if (!attendanceID) {
        return console.log(
          "toAttendance",
          "invalid attendanceID",
          this.attendanceID,
          this.attendance
        );
      }

      this.$router
        .push({
          path: `/attendance/chat/${this.attendance._id}`,
        })
        .catch(() => {
          /* ignore */
        });
    },
    startNotificationSound() {
      if (this.notificationSoundInterval) {
        clearInterval(this.notificationSoundInterval);
      }

      this.notificationSoundInterval = setInterval(() => {
        this.playNotificationSound();
      }, 1500);
    },
    playNotificationSound() {
      Howler.autoUnlock = true;

      // Se já existir um som tocando, pare-o antes de tocar um novo
      if (this.notificationSound) {
        this.notificationSound.stop();
      }

      // Cria um novo som
      this.notificationSound = new Howl({
        src: [
          "https://storage.googleapis.com/notification-song/ringtone-1-46486.mp3",
        ],
        volume: 0.5, // Volume deve estar entre 0.0 e 1.0
      });

      // melodia de notificação
      this.notificationSound.play();
      setTimeout(() => {
        this.notificationSound.play();
      }, 300);
    },
    stopNotificationSound() {
      if (this.notificationSoundInterval) {
        clearInterval(this.notificationSoundInterval);
      }
    },
    async handleSession() {
      const token = this.$store.getters.accessToken;

      if (token) {
        try {
          const { data } = await loadSession();

          this.setUser(data);
          if (data.status === "online") this.getAttendancesWaitingMe();
        } catch {
          this.signOut(false);
        }
      }

      this.loaded = true;
    },

    async getAttendancesWaitingMe() {
      try {
        const { data } = await getAttendancesWaitingMe();

        if (!data) return;

        this.newAttendanceDialog = true;
        this.newAttendanceID = data._id;
      } catch (error) {
        console.log(error);
      }
    },

    async signOut(handleRequest = true) {
      try {
        if (handleRequest) await signOut();
        await this.setSignOut();
        this.$router.push({ path: "/auth" }).catch(() => {
          /* ignore */
        });
      } catch (error) {
        this.handleAlert(error.data?.message || error.message, "error");
      }
    },
    async changeStatus(event) {
      try {
        const payload = {
          status: event,
        };

        await updateUser(this.user._id, payload);
        this.handleSession();
      } catch (error) {
        this.handleAlert(error.data?.message || error.message, "error");
      }
    },
    handleDrawer() {
      this.$refs.navDrawer.handleDrawer();
    },
    async onQueue(data) {
      // console.log("@queue", data);

      try {
        const { _id: attendanceID, groupID, subgroupID, deskID, action } = data;

        if (!this._isAvailable) {
          return console.log("onQueue:not-available");
        }

        const isRequested = ["join", "transfer"].includes(action);
        if (!isRequested) {
          // Caso exista um atendimento notificando em aberto e ele saiu da fila, entao o dialog deve ser fechado
          if (this.newAttendanceID === attendanceID) {
            this.newAttendanceDialog = false;
          }

          return console.log("onQueue:not-requested");
        }

        if (this._isAdmin) {
          // console.log("::isDirector");
          const isLinked = await this.isLinkedGroup(groupID);
          if (isLinked) {
            // console.log("isLinkedGroup");
            const groups = await getJurisdictions({
              personID: this.user._id,
              limit: 100,
            });

            const fifoID = await this.handleGetAttendanceFIFO({
              groupIDs: groups.data.map((g) => g._id),
            });

            this.newAttendanceDialog = true;
            this.newAttendanceID = fifoID;
          }
        }

        if (this._isModerator) {
          // console.log("::isCoordinator");
          const isLinked = await this.isLinkedSubgroup(subgroupID);
          if (isLinked) {
            // console.log("isLinkedSubgroup");

            const subgroups = await getCourts({
              personID: this.user._id,
              limit: 100,
            });

            const fifoID = await this.handleGetAttendanceFIFO({
              subgroupIDs: subgroups.data.map((g) => g._id),
            });

            this.newAttendanceDialog = true;
            this.newAttendanceID = fifoID;
          }
        }

        if (this._isOperator) {
          // console.log("::isAttendant");
          const isLinked = await this.isLinkedDesk(deskID);
          if (isLinked) {
            // console.log("isLinkedDesk");

            const desks = await getCounters({
              personID: this.user._id,
              limit: 100,
            });

            const fifoID = await this.handleGetAttendanceFIFO({
              deskIDs: desks.data.map((g) => g._id),
            });

            this.newAttendanceDialog = true;
            this.newAttendanceID = fifoID;
          }
        }
      } catch (error) {
        this.processError(error, "onQueue");
        this.handleAlert(
          "Ocorreu um erro ao receber os dados da fila",
          "error"
        );
      }
    },
    onStarted() {},
    async handleStartAttendance() {
      this.loadingOverlay = true;

      try {
        const attendanceID = this.newAttendanceID;
        if (!attendanceID) {
          throw new Error("Ocorreu um erro ao iniciar o atendimento.");
        }

        const payload = {
          attendanceID,
        };

        await startAttendance(payload, (error, data) => {
          console.log("handleStartAttendance:data", data);

          if (error) {
            this.loadingOverlay = false;
            const message =
              this.processError(error, "handleStartAttendance") ||
              "Ocorreu um erro ao iniciar atendimento.";
            this.handleAlert(message, "warning");
          }

          this.setAttendance(data);

          this.loadingOverlay = false;
        });
      } catch (error) {
        this.processError(error, "handleStartAttendance");
        this.loadingOverlay = false;
      } finally {
        this.newAttendanceDialog = false;
        this.newAttendanceID = null;
      }
    },
    async handleGetAttendanceFIFO(payload) {
      try {
        const defaultQuery = {
          sortBy: "queuedAt",
          sortOrder: "asc",
          limit: 1,
          status: ["waiting"],
        };

        const { data } = await getAttendances({
          ...defaultQuery,
          ...payload,
        });
        return data[0]?._id;
      } catch (error) {
        this.processError(error, "handleGetAttendanceFIFO");
      }
    },
    async handleJoinAttendance() {
      this.loadingOverlay = true;

      try {
        this.handleAlert("Reconectando...", "info");

        const payload = {
          protocol: this.attendance.protocol,
        };

        await attendanceJoin(payload, (error, data) => {
          if (error) {
            this.loadingOverlay = false;
            this.handleAlert(
              "Falha ao Reconectar ao atendimento. Por favor, tente novamente."
            );
            return this.processError(error, "handleJoinAttendance");
          }

          this.handleAlert("Reconectado!", "success");

          this.setAttendance(data);
          this.loadingOverlay = false;
        });
      } catch (error) {
        this.processError(error, "handleJoinAttendance");
        this.loadingOverlay = false;
      }
    },
    async isLinkedDesk(deskID) {
      try {
        if (!deskID) {
          throw new Error("deskID is required");
        }

        const { data } = await getCounter(deskID);
        if (!data) {
          throw new Error("desk not found");
        }

        const isLinked = data.attendants?.some((e) => e._id === this.user._id);
        if (!isLinked) {
          // console.log("linkedDesk = { person not is linked }");
          return false;
        }

        return true;
      } catch (error) {
        this.processError(error, "isLinkedDesk");
        return false;
      }
    },
    async isLinkedSubgroup(subgroupID) {
      try {
        if (!subgroupID) {
          throw new Error("subgroupID is required");
        }

        const { data } = await getCourt(subgroupID);
        if (!data) {
          throw new Error("subgroup not found");
        }

        const isLinked = data.people?.some((e) => e._id === this.user._id);
        if (!isLinked) {
          // console.log("linkedSubgroup = { person not is linked }");
          return false;
        }

        return true;
      } catch (error) {
        this.processError(error, "isLinkedSubgroup");
        return false;
      }
    },
    async isLinkedGroup(groupID) {
      try {
        if (!groupID) {
          throw new Error("groupID is required");
        }

        const { data } = await getJurisdiction(groupID);
        if (!data) {
          throw new Error("group not found");
        }

        const isLinked = data.people?.some((e) => e._id === this.user._id);
        if (!isLinked) {
          // console.log("linkedGroup = { person not is linked }");
          return false;
        }

        return true;
      } catch (error) {
        this.processError(error, "isLinkedGroup");
        return false;
      }
    },
    processError(error, name = "") {
      const message =
        error?.response?.data?.message ||
        error?.data?.message ||
        error?.message;
      console.log(`${name}:error = { ${message} }`);
      return message;
    },
    handleAlert(message, type, fixed, timeout = 3000) {
      this.$refs.alertBar?.handleAlert(message, type, fixed, timeout);
    },
    handlePingSession() {
      if (this.interval) clearInterval(this.interval);

      this.interval = setInterval(() => {
        // console.log("ping");
        pingEmit();
      }, 10000);
    },

    handleChangeToken(newVal, oldVal) {
      if (newVal && oldVal) {
        disconnectSocket();
        clearInterval(this.interval);

        setTimeout(() => {
          connectSocket();
          this.handlePingSession();

          this.$root.$emit("socket-reconnected");
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.float-drawer {
  z-index: 999999;
  top: 0.75rem !important;
  left: 0.25rem !important;
}

.button-pulse {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
