<template>
  <v-dialog v-model="dialog" :max-width="width">
    <v-card class="rounded-lg pa-8" color="neutral" :loading="loading">
      <div class="d-flex align-start">
        <div class="d-flex flex-column fill-width">
          <div class="d-flex align-center">
            <img
              :src="this.user.photo"
              alt="Foto do Usuário"
              class="imgPerson rounded-lg mr-4"
            />

            <div>
              <div>{{ this.user.name }}</div>
              <div>{{ this.user.email }}</div>
            </div>
          </div>

          <section v-if="$can('attendances')">
            <v-divider class="my-2" />

            <div class="font-weight-bold text-body-2">
              Módulo de atendimento:
            </div>

            <div class="mb-2">{{ _attendanceRole }}</div>

            <v-switch
              v-if="_isAdmin || _isModerator"
              class="mt-0 pt-0"
              max-width="1rem"
              max-height="1rem"
              v-model="user.settings.attendancesNotification"
              @change="handleUpdateSettingsAttendanceNotifications"
              :label="`Permitir Notificações de Atendimentos`"
              hide-details
              :disabled="loading"
            >
            </v-switch>
          </section>

          <section v-if="$can('audiences')">
            <v-divider class="my-2" />

            <div class="font-weight-bold text-body-2">Módulo de audiência:</div>
            <div>{{ _audienceRole }}</div>
          </section>
        </div>
      </div>

      <v-row justify="center" class="mt-5">
        <v-btn
          class="text-none"
          color="primary"
          depressed
          dark
          @click="close()"
        >
          Fechar
        </v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { getPermissionGroups } from "@/services/permissions";

export default {
  name: "ProfileDialog",

  props: {
    width: {
      type: [String, Number],
      default: "600",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialog: false,
      audienceGroups: [],
    };
  },

  computed: {
    ...mapState(["user"]),
    ...mapGetters(["attendanceRole"]),

    _isAdmin() {
      return this.attendanceRole === "admin";
    },

    _isModerator() {
      return this.attendanceRole === "moderator";
    },

    _attendanceRole() {
      switch (this.attendanceRole) {
        case "admin":
          return "Administrador";
        case "moderator":
          return "Gestor";
        case "operator":
          return "Atendente";
        default:
          return "--";
      }
    },

    _audienceRole() {
      const audienceEntities = this.user?.permissionGroups?.filter(
        (el) => !!this.audienceGroups?.find((perm) => perm._id === el._id)
      );

      return audienceEntities?.length ? audienceEntities[0].name : "--";
    },
  },

  beforeMount() {
    if (!this.user?.settings) {
      this.user.settings = {
        attendancesNotification: false,
      };
    }

    this.getAudiencePermissionGroups();
  },

  methods: {
    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    handleUpdateSettingsAttendanceNotifications(value) {
      const { type, language, status } = this.user;

      const payload = {
        _id: this.user._id,
        ...(!!type && { type }),
        ...(!!language && { language }),
        ...(!!status && { status }),
        settings: {
          ...this.user.settings,
          attendancesNotification: value,
        },
      };
      this.$emit("update", payload);
    },

    async getAudiencePermissionGroups() {
      if (!this.$can("audiences")) return;

      try {
        const payload = {
          module: "audiences",
          type: "role",
        };

        const { data } = await getPermissionGroups(payload);

        this.audienceGroups = data;
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
