<template>
  <v-container>
    <div class="text-h5 font-weight-bold">Olá, {{ user.name }} 👋</div>
    <div class="primary--text text-h4 font-weight-bold mb-8">Visão Geral</div>

    <FilterPeriod @change="handlePeriod($event)" />

    <div class="d-flex align-center mb-4">
      <FilterSelector :filters="_filterOptions" @apply="handleFilter($event)" />
    </div>

    <div v-if="loading" class="d-flex align-center justify-center py-6">
      <v-progress-circular indeterminate color="primary" />
    </div>

    <div v-else>
      <section class="mb-6">
        <div class="text-h5 mb-2">Atividades</div>

        <v-row>
          <v-col
            v-for="(item, i) in analytics"
            cols="12"
            sm="6"
            md="4"
            :key="i"
          >
            <v-card class="pa-8 custom-card" height="100%">
              <div class="d-flex flex-column align-start">
                <v-icon class="mb-1" color="primary" x-large>
                  {{ item.icon }}
                </v-icon>

                <div>
                  <span class="text-h4">{{ item.value }}</span>
                  <span class="text-h6 font-weight-regular">
                    {{ item.label }}
                  </span>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </section>

      <v-card class="pa-8 custom-card">
        <div class="text-h5 font-weight-regular mb-2">
          Gráfico de Atendimentos
        </div>

        <BarChart :series="series" :categories="categories" />
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { getDashboard, getAttendancesGraph } from "@/services/users";
import { getCounters } from "@/services/counters";
import { getJurisdictions } from "@/services/jurisdictions";
import { getCourts } from "@/services/courts";
import { getUsers } from "@/services/users";
import { handleAlert } from "@/utils";
import BarChart from "@/components/barChart/BarChart";
import FilterPeriod from "@/components/filterPeriod/FilterPeriod";
import FilterSelector from "@/components/filterSelector/FilterSelector";

import moment from "moment";
moment.locale("pt-BR");

export default {
  name: "Home",

  components: { BarChart, FilterPeriod, FilterSelector },

  data() {
    return {
      loading: true,
      period: {
        startDate: "",
        endDate: "",
      },
      filters: {},
      analytics: [
        {
          icon: "mdi-account-multiple",
          label: "Em Espera",
          value: 0,
        },
        {
          icon: "mdi-message-flash",
          label: "Atendimentos",
          value: 0,
        },
        {
          icon: "mdi-clock-outline",
          label: "Tempo médio de atendimento",
          value: 0,
        },
      ],
      series: [
        {
          name: "Resolvidos",
          data: [],
        },
        {
          name: "Não Resolvidos",
          data: [],
        },
      ],
      categories: [],
      counters: [],
      jurisdictions: [],
      courts: [],
      attendants: [],
    };
  },

  beforeMount() {
    this.getCounters();
    this.getJurisdictions();
    this.getCourts();

    if (this._isAdmin || this._isModerator) {
      this.getUsers();
    }
  },

  computed: {
    ...mapState(["user"]),
    ...mapGetters(["attendanceRole"]),

    _isOperator() {
      return this.attendanceRole === "operator";
    },

    _isModerator() {
      return this.attendanceRole === "moderator";
    },

    _isAdmin() {
      return this.attendanceRole === "admin";
    },

    _filterOptions() {
      return [
        {
          label: this.$t("desk"),
          key: "deskID",
          type: "select",
          items: this.counters,
        },
        {
          label: this.$t("group"),
          key: "comarcaID",
          type: "select",
          items: this.jurisdictions,
        },
        {
          label: this.$t("subgroup"),
          key: "varaID",
          type: "select",
          items: this.courts,
        },
        ...(this._isOperator
          ? [
              {
                label: "Atendente",
                key: "personID",
                type: "select",
                items: this.attendants,
              },
            ]
          : []),
      ];
    },
  },

  methods: {
    async getData() {
      this.loading = true;

      await Promise.all([this.getInformations(), this.getAttendancesGraph()]);

      this.loading = false;
    },

    async getInformations() {
      try {
        const payload = {
          ...(this._isOperator && { personID: this.user._id }),
          ...(this.filters.personID && { personID: this.filters.personID }),
          ...(this.filters.comarcaID && {
            comarcaID: this.filters.comarcaID,
          }),
          ...(this.filters.varaID && { varaID: this.filters.varaID }),
          ...(this.filters.deskID && {
            deskID: this.filters.deskID,
          }),
          ...(this.period.startDate && { startDate: this.period.startDate }),
          ...(this.period.endDate && { endDate: this.period.endDate }),
          personID: this.user._id,
        };

        const { data } = await getDashboard(payload);

        this.analytics[0].value = data.attendanceQueue;
        this.analytics[1].value = data.totalAttendance;
        this.analytics[2].value = this.handleTime(
          data.averageDurationInMinutes
        );
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async getAttendancesGraph() {
      try {
        this.loading = true;

        const payload = {
          ...(this._isOperator && { personID: this.user._id }),
          ...(this.filters.comarcaID && {
            comarcaID: this.filters.comarcaID,
          }),
          ...(this.filters.varaID && { varaID: this.filters.varaID }),
          ...(this.filters.deskID && {
            deskID: this.filters.deskID,
          }),
          ...(this.period.startDate && { startDate: this.period.startDate }),
          ...(this.period.endDate && { endDate: this.period.endDate }),
          personID: this.user._id,
        };

        const { data } = await getAttendancesGraph(payload);
        const series = [];

        Object.values(data).forEach((array, i) => {
          series.push([]);

          this.categories.forEach((cat, j) => {
            series[i].push(0);

            array.forEach((el) => {
              const format =
                this.period.startDate &&
                this.period.endDate &&
                this.period.startDate === this.period.endDate
                  ? "DD/MM/YYYY"
                  : "MMM YYYY";

              const category = moment({
                month: el.date.mes - 1,
                year: el.date.ano,
              }).format(format);

              if (cat === category) {
                series[i][j] = i === 0 ? el.totalResolved : el.totalUnresolved;
              }
            });
          });
        });

        this.series[0].data = series[0];
        this.series[1].data = series[1];
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async getCounters() {
      try {
        const payload = {
          limit: 100,
          offset: 0,
          personID: this.user._id,
        };

        const { data } = await getCounters(payload);

        this.counters = data.map((el) => ({
          label: el.name,
          value: el._id,
        }));
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async getJurisdictions() {
      try {
        const payload = {
          limit: 100,
          offset: 0,
          personID: this.user._id,
        };

        const { data } = await getJurisdictions(payload);

        this.jurisdictions = data.map((el) => ({
          label: el.name,
          value: el._id,
        }));
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async getCourts() {
      try {
        const payload = {
          limit: 100,
          offset: 0,
          personID: this.user._id,
        };

        const { data } = await getCourts(payload);

        this.courts = data.map((el) => ({
          label: el.name,
          value: el._id,
        }));
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async getUsers() {
      try {
        this.loading = true;

        const payload = {
          limit: 100,
          offset: 0,
          type: "attendant",
        };

        const { data } = await getUsers(payload);

        this.attendants = data.map((el) => ({
          label: el.name,
          value: el._id,
        }));
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    handlePeriod(event) {
      const initialDate = moment(event.initialDate);
      const finalDate = moment(event.finalDate);
      let months = [];

      if (
        event.initialDate &&
        event.finalDate &&
        event.initialDate === event.finalDate
      )
        months = [moment().format("MMM YYYY")];
      else
        while (
          initialDate.isBefore(finalDate) ||
          initialDate.isSame(finalDate, "month")
        ) {
          months.push(initialDate.format("MMM YYYY"));
          initialDate.add(1, "month");
        }

      this.categories = months;

      this.period = {
        startDate: event.initialDate
          ? moment(event.initialDate).format("YYYY-MM-DDT00:00:00Z")
          : "",
        endDate: event.finalDate
          ? moment(event.finalDate).format("YYYY-MM-DDT23:59:59Z")
          : "",
      };

      this.getData();
    },

    handleFilter(event) {
      this.filters = structuredClone(event);

      this.getData();
    },

    handleTime(value) {
      const duration = moment.duration(value, "minutes");
      return moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
    },

    handleAlert,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
