import api from "./index";

export async function getPermissionGroups(params) {
  return api.get(`/permission-groups`, { params: params });
}

export async function getPermissions() {
  return api.get(`/permission-groups/permissions`);
}

export async function updatePermissionGroups(groupID, body) {
  return api.put(`/permission-groups/${groupID}`, body);
}
