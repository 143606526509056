<template>
  <div class="d-flex align-center justify-center">
    <v-chip-group
      v-model="selected"
      active-class="primary white--text"
      show-arrows
      mandatory
    >
      <v-chip
        v-for="(period, i) in periods"
        class="elevation-0"
        :disabled="disabled"
        :key="period"
        :outlined="selected !== i"
      >
        {{ period }}
      </v-chip>

      <v-dialog ref="dialog" v-model="modal" persistent width="20.125rem">
        <template v-slot:activator="{ on }">
          <v-chip v-on="on" :disabled="disabled" :outlined="selected !== 5">
            <span> Período Personalizado </span>

            <span v-if="selected === 7 && dates[0] && dates[1]" class="ml-2">
              ({{ dates[0] }} - {{ dates[1] }})
            </span>
          </v-chip>
        </template>

        <v-card class="rounded-lg" color="neutral3">
          <section class="pa-4 pb-0">
            <v-date-picker
              v-model="dates"
              color="primary"
              :max="maxDate"
              scrollable
              no-title
              range
            />
          </section>

          <div class="d-flex pa-4">
            <v-spacer />

            <v-btn class="text-none" plain @click="cancelRange()">
              Cancelar
            </v-btn>

            <v-btn
              :disabled="!_validRange"
              class="text-none"
              color="primary"
              depressed
              @click="rangeSubmit()"
            >
              Aplicar
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-chip-group>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      selected: 3,
      modal: false,
      periods: [
        "Últimos 12 meses",
        "Últimos 6 meses",
        "Últimos 3 meses",
        "Este mês",
        "Hoje",
      ],
      dates: ["", ""],
      appliedDates: ["", ""],
      maxDate: moment().format(),
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  beforeMount() {
    this.handleDate(this.selected);
  },

  watch: {
    dates(value) {
      if (value.length > 1) {
        if (value[0] > value[1]) {
          const temp = value[0];

          this.dates.splice(0, 1);
          this.dates.push(temp);
        }
      }
    },

    selected(value) {
      this.handleDate(value);
    },
  },

  computed: {
    _validRange() {
      return this.dates.length && this.dates[0] && this.dates[1];
    },
  },

  methods: {
    submit() {
      const payload = {
        initialDate: this.dates[0],
        finalDate: this.dates[1],
      };

      this.appliedDates = structuredClone(this.dates);

      this.$emit("change", payload);
    },

    handleDate(value) {
      this.dates = [];

      // all
      if (value === 0) {
        this.dates[0] = moment().subtract(11, "months").format("YYYY-MM-01");
        this.dates[1] = moment().format("YYYY-MM-DD");
      }

      // last 6 months
      if (value === 1) {
        this.dates[0] = moment().subtract(5, "months").format("YYYY-MM-01");
        this.dates[1] = moment().format("YYYY-MM-DD");
      }

      // last 3 months
      if (value === 2) {
        this.dates[0] = moment().subtract(2, "months").format("YYYY-MM-01");
        this.dates[1] = moment().format("YYYY-MM-DD");
      }

      // this month
      if (value === 3) {
        this.dates[0] = moment().startOf("month").format("YYYY-MM-01");
        this.dates[1] = moment().format("YYYY-MM-DD");
      }

      if (value === 4) {
        this.dates[0] = moment().format("YYYY-MM-DD");
        this.dates[1] = moment().format("YYYY-MM-DD");
      }

      // date pick
      if (value === 5) {
        this.dates[0] = "";
        this.dates[1] = "";

        return;
      }

      this.submit();
    },

    rangeSubmit() {
      this.modal = false;
      this.submit();
    },

    cancelRange() {
      this.dates = structuredClone(this.appliedDates);
      this.modal = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
