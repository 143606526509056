<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      class="custom-drawer"
      width="18.75rem"
      color="gray2"
      height="100%"
      dark
      app
    >
      <div class="d-flex align-center px-4 py-2">
        <v-menu
          content-class="mt-2"
          min-width="12.5rem"
          :close-on-content-click="false"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              bordered
              offset-x="30"
              offset-y="8"
              :color="_currentStatus"
              bottom
              dot
            >
              <v-avatar
                v-bind="attrs"
                v-on="on"
                class="mr-6"
                size="2.5rem"
                color="gray4"
                rounded
              >
                <v-img v-if="user.photo" :src="user.photo" />
                <v-icon v-else>mdi-account-outline</v-icon>
              </v-avatar>
            </v-badge>
          </template>

          <v-list class="pt-0">
            <v-list-item>
              <v-list-item-title>Definir status</v-list-item-title>
            </v-list-item>

            <v-divider />

            <v-list-item
              v-for="(item, i) in status"
              :key="i"
              :class="{ 'primary--text': user?.status === item.value }"
              dense
              @click="changeStatus(item.value)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon :color="item.color" x-small>mdi-circle</v-icon>
              </v-list-item-icon>

              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-spacer />

        <v-btn class="custom-btn mr-2" small icon>
          <v-icon small @click="notificationBell">mdi-bell</v-icon>
        </v-btn>

        <v-menu
          content-class="mt-2"
          min-width="12.5rem"
          :close-on-content-click="false"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="custom-btn" small icon>
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="handleDialog()">
              <v-list-item-icon class="mr-2">
                <v-icon small>mdi-account</v-icon>
              </v-list-item-icon>

              <v-list-item-title>Meu Perfil</v-list-item-title>
            </v-list-item>
            <v-list-item @click="signOut()">
              <v-list-item-icon class="mr-2">
                <v-icon small>mdi-logout</v-icon>
              </v-list-item-icon>

              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <section class="d-flex flex-column pb-4">
        <v-divider class="gray1" />

        <template v-for="(item, i) in _routes">
          <div :key="i">
            <template v-if="item.section">
              <div v-if="item.permission">
                <v-divider class="mb-4 gray1" />

                <div
                  class="white--text text-subtitle-1 font-weight-bold ml-4 mb-4"
                >
                  {{ item.title }}
                </div>

                <template v-for="(subitem, n) in item.routes">
                  <router-link
                    v-show="subitem.permission"
                    :key="n"
                    v-slot="{ href, navigate, isActive }"
                    :to="subitem.path"
                    :exact="subitem.exactAtive"
                    custom
                  >
                    <v-list-item
                      class="px-4"
                      :class="isActive ? 'gray3' : 'transparent'"
                      :href="href"
                      @click="navigate"
                    >
                      <v-list-item-avatar rounded>
                        <v-icon v-if="subitem.icon">
                          {{ subitem.icon }}
                        </v-icon>

                        <div v-else-if="subitem.svg" v-html="subitem.svg"></div>

                        <v-avatar v-else size="2rem" color="gray4" rounded />
                      </v-list-item-avatar>

                      <v-list-item-title class="gray6--text">
                        {{ subitem.label }}
                      </v-list-item-title>
                    </v-list-item>
                  </router-link>
                </template>
              </div>
            </template>

            <template v-else>
              <router-link
                v-show="item.permission"
                v-slot="{ href, navigate, isActive }"
                :to="item.path"
                custom
              >
                <v-list-item
                  class="px-4"
                  :class="isActive ? 'gray3' : 'transparent'"
                  :href="href"
                  @click="navigate"
                >
                  <v-list-item-avatar rounded>
                    <v-icon v-if="item.icon">
                      {{ item.icon }}
                    </v-icon>

                    <div v-else-if="item.svg" v-html="item.svg"></div>

                    <v-avatar v-else size="2rem" color="gray4" rounded />
                  </v-list-item-avatar>

                  <v-list-item-title class="gray6--text">
                    {{ item.label }}
                  </v-list-item-title>
                </v-list-item>
              </router-link>
            </template>
          </div>
        </template>
      </section>

      <ProfileDialog
        ref="profileDialog"
        :loading="loading.user"
        @update="handleUpdateUser"
      />
    </v-navigation-drawer>

    <v-dialog v-model="unityDialog" max-width="25rem" persistent>
      <v-card class="pa-6 rounded-lg">
        <div class="d-flex align-center mb-4">
          <div class="text-body-1 font-weight-bold">Selecione uma unidade</div>

          <v-spacer />

          <v-btn icon @click="cancelUnity()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-form v-model="valid.unity" ref="formUnity" autocomplete="off">
          <v-select
            v-model="form.unity"
            :items="unities"
            placeholder="Selecione uma unidade"
            item-value="name"
            item-text="name"
            :rules="_rules"
            outlined
          ></v-select>
        </v-form>

        <v-btn
          class="text-none"
          color="primary"
          @click="submitUnity()"
          block
          large
        >
          Confirmar
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getCourts } from "@/services/courts";
import { updateUser } from "@/services/users";
import { handleAlert } from "@/utils";
import ProfileDialog from "./partials/profileDialog/ProfileDialog.vue";

export default {
  name: "NavDrawer",

  components: {
    ProfileDialog,
  },

  data() {
    return {
      drawer: true,
      loading: {
        user: false,
        unities: false,
      },
      status: [
        {
          color: "green",
          value: "online",
          label: "Disponível",
        },
        {
          color: "red",
          value: "busy",
          label: "Ocupado",
        },
        {
          color: "amber",
          value: "away",
          label: "Ausente",
        },
        {
          color: "grey",
          value: "offline",
          label: "Invisível",
        },
      ],
      unityDialog: false,
      unities: [],
      valid: {
        unity: false,
      },
      form: {
        unity: "",
      },
    };
  },

  computed: {
    ...mapState(["user"]),

    _currentStatus() {
      const status = this.user.status || "online";
      const result = this.status.find((el) => el.value === status);
      return result ? result.color : "green";
    },

    _routes() {
      return [
        {
          label: "Usuários",
          path: "/users",
          icon: "mdi-account-multiple-outline",
          permission: this.$can("people"),
        },
        {
          label: "Permissões",
          path: "/permissions",
          icon: "mdi-lock-open-check-outline",
          permission: this.$can("permission-groups"),
        },
        {
          section: true,
          title: "Módulo de atendimento",
          permission:
            this.$can("desks") ||
            this.$can("reports") ||
            this.$can("attendances"),
          routes: [
            {
              label: "Visão Geral",
              path: "/home",
              icon: "mdi-view-dashboard",
              permission: true,
            },
            {
              label: "Balcões",
              path: "/counters",
              icon: "mdi-view-day-outline",
              permission: this.$can("desks"),
            },
            {
              label: "Relatórios",
              path: "/reports",
              icon: "mdi-chart-areaspline",
              permission: this.$can("reports"),
            },
            {
              label: "Fila de Atendimento",
              path: "/attendance",
              icon: "mdi-chart-box",
              permission: this.$can("attendances"),
            },
          ],
        },
        {
          section: true,
          title: "Módulo de audiência",
          permission: this.$can("audiences") || this.$can("entities"),
          routes: [
            {
              label: "Audiências",
              path: "/audiences",
              svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-gavel"><path d="m14.5 12.5-8 8a2.119 2.119 0 1 1-3-3l8-8"/><path d="m16 16 6-6"/><path d="m8 8 6-6"/><path d="m9 7 8 8"/><path d="m21 11-8-8"/></svg>`,
              permission: this.$can("audiences"),
            },
            {
              label: this.$t("entities"),
              path: "/entities",
              icon: "mdi-view-day-outline",
              permission: this.$can("entities"),
            },
          ],
        },
      ];
    },

    _rules() {
      return [(v) => !!v || "Campo obrigatório"];
    },
  },

  methods: {
    handleDrawer() {
      this.drawer = !this.drawer;
    },

    changeStatus(value) {
      this.$emit("change-status", value);
    },

    signOut() {
      this.$emit("sign-out");
    },

    handleMenuAction(action) {
      if (action === "audience") {
        this.getCourts();
        this.unityDialog = true; // href: "https://audiencias-app-673194685875.us-central1.run.app/escolher-vara",
      }
    },

    submitUnity() {
      if (!this.$refs.formUnity.validate()) return;

      const unity = this.form.unity
        .trim()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const newName = unity
        .toLowerCase()
        .replace(/[^\w\s]/g, "")
        .replace(/\s+/g, "-");

      window.location.href = `https://audiencias-app-673194685875.us-central1.run.app/admin/${newName}`;
    },

    cancelUnity() {
      this.unityDialog = false;
    },

    async getCourts() {
      try {
        this.loading.unities = true;

        const payload = {
          limit: 100,
          offset: 0,
          enableAudience: true,
        };

        const { data } = await getCourts(payload);

        this.unities = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.unities = false;
      }
    },

    handleDialog() {
      this.$refs.profileDialog.open();
    },
    notificationBell() {
      this.$emit("notification-bell");
    },
    async handleUpdateUser(body) {
      this.loading.user = true;

      try {
        const payload = structuredClone(body);
        const userID = payload._id || this.user._id;
        delete payload._id;

        await updateUser(userID, payload);
        this.$root.$emit("load-session");

        this.handleAlert("Perfil atualizado.", "success");
      } catch (error) {
        const message =
          error?.response?.data?.message ||
          error?.message ||
          "Erro ao atualizar perfil";
        console.log(message);
        this.handleAlert(message, "error");
      } finally {
        this.loading.user = false;
      }
    },

    handleAlert,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
