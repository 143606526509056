<template>
  <div class="d-flex flex-column">
    <v-tabs
      v-model="tab"
      background-color="transparent"
      height="2rem"
      show-arrows
    >
      <v-tab
        v-for="(item, i) in tabs"
        class="text-none text-body-1 font-weight-bold"
        :disabled="disabled || item.disabled"
        :key="i"
        @click="submit(item, i)"
      >
        {{ item.label }}
      </v-tab>
    </v-tabs>

    <v-divider v-if="!hideDivider" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    tabs: {
      type: Array,
      default: () => [], // [{ label, value }]
    },

    default: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    hideDivider: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    tab: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  mounted() {
    this.handleDefault();
  },

  methods: {
    submit(data, index) {
      this.tab = index;

      this.$emit("change", data);
    },

    handleDefault() {
      if (this.default) {
        this.tab = this.tabs.findIndex((el) => el.value === this.default);

        if (this.tab < 0) this.tab = 0;
        this.submit(this.tabs[this.tab], this.tab);
      }
    },

    setTab(value) {
      this.tab = this.tabs.findIndex((el) => el.value === value);
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
