<template>
  <v-dialog v-model="dialog" :max-width="width" :persistent="persistent">
    <v-card class="rounded-lg pa-6" color="neutral">
      <div v-if="!hideHeader" class="header d-flex flex-column mb-2">
        <div class="text-h5 font-weight-bold primary--text">
          {{ title }}
        </div>

        <div v-if="subtitle" class="text-body-2">
          {{ subtitle }}
        </div>

        <v-btn icon small absolute right @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="content">
        <slot></slot>
      </div>

      <div v-if="!hideFooter" class="footer d-flex mt-6">
        <v-spacer />

        <v-btn
          v-for="(item, i) in footerButtons"
          class="ml-3 font-weight-regular"
          :elevation="!item.depressed ? '1' : '0'"
          :color="item.color"
          :loading="loading"
          :disabled="loading"
          :key="i"
          @click="handleAction(item.action)"
        >
          <span> {{ item.label }} </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },

  props: {
    title: {
      type: String,
      default: "",
    },

    subtitle: {
      type: String,
      default: "",
    },
    width: {
      type: [String, Number],
      default: "600",
    },

    footerButtons: {
      type: Array,
      default: () => [
        {
          label: "Cancelar",
          color: "secondary",
          action: "close",
        },
        {
          label: "Confirmar",
          color: "primary",
          action: "submit",
        },
      ],
    },

    hideHeader: {
      type: Boolean,
      default: false,
    },

    hideFooter: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
  },

  created() {
    this.$on("show-notification", this.open);
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.$emit("closed");
      } else {
        this.$emit("opened");
      }
    },
  },

  methods: {
    handleAction(action) {
      this.$emit(action);

      if (action === "close") {
        this.close();
      }
    },

    open() {
      this.dialog = true;
      this.$emit("dialogOpened");
    },

    close() {
      this.dialog = false;
      this.$emit("dialogClosed");
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
