import store from "@/store";

export function can(key) {
  return store?.getters?.user?.permissions?.includes(key);
}

export default {
  install(Vue) {
    Vue.prototype.$can = can;
  },
};
