<template>
  <div class="d-flex align-center justify-center">
    <v-btn
      class="mr-2"
      :disabled="localPage === 1"
      icon
      small
      @click="handlePage('back')"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

    <span class="text-body-2 font-weight-bold">{{ localPage }}</span>

    <v-btn
      class="ml-2"
      :disabled="localPage >= totalPages"
      icon
      small
      @click="handlePage('next')"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Pagination",

  data() {
    return {
      localPage: 1,
    };
  },

  props: {
    page: {
      type: Number,
      default: 1,
    },

    offset: {
      type: Number,
      default: 0,
    },

    limit: {
      type: Number,
      default: 15,
    },

    totalPages: {
      type: Number,
      default: 1,
    },
  },

  beforeMount() {
    this.startPagination();
  },

  methods: {
    handlePage(type) {
      this.localPage += type === "next" ? 1 : -1;

      const payload = {
        page: this.localPage,
        offset: this.offset + (type === "next" ? this.limit : -this.limit),
      };

      this.$emit("change:pagination", payload);
    },

    startPagination() {
      this.localPage = this.page;
    },
  },
};
</script>

<style></style>
