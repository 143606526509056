<template>
  <v-container>
    <div class="text-h5 font-weight-bold mb-2">{{ $t("desks") }}</div>

    <TabNavigation
      class="mb-4"
      :tabs="_tabs"
      v-model="tab"
      @change="handleTabs($event)"
    />

    <router-view />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TabNavigation from "@/components/tabNavigation/TabNavigation.vue";

export default {
  name: "Counters",

  components: {
    TabNavigation,
  },

  data() {
    return {
      tab: 0,
    };
  },

  created() {
    const tabIndex = this._tabs.findIndex(
      (el) => el.value === this.$route.name
    );

    if (tabIndex > -1) {
      this.tab = this._tabs.findIndex((el) => el.value === this.$route.name);
    }
  },

  computed: {
    ...mapState(["user"]),
    ...mapGetters(["attendanceRole"]),

    _isModerator() {
      return this.attendanceRole === "moderator";
    },

    _tabs() {
      const tabs = [
        {
          label: this.$t("desks"),
          value: "Counters",
        },
        {
          label: this.$t("subgroup"),
          value: "Courts",
        },
        {
          label: this.$t("group"),
          value: "Jurisdictions",
        },
      ];

      if (this._isModerator) {
        tabs.pop();
      }

      return tabs;
    },
  },

  methods: {
    handleTabs(event) {
      if (event.value === this.tab) return;

      if (this.$route.name !== event.value) {
        this.tab = event.value;
        this.$router.push({ name: event.value }).catch(() => {});
      }
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
