import store from "@/store";
import { io } from "socket.io-client";
import { ensureSocketListeners } from "./attendances";
import { ensureAudienceSocketListeners } from "./audiences";
import { ensureEntitiesSocketListeners } from "./entities";

let socket = null;

export const connectSocket = () => {
  if (socket) {
    console.warn("Socket já está conectado.");
    return socket;
  }

  const options = {
    auth: {
      token: store.getters.accessToken,
      xApiKey: process.env.VUE_APP_API_KEY,
    },
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
  };

  console.log(options);

  socket = io(process.env.VUE_APP_SOCKET_URL, options);

  socket.on("connect", () => {
    console.log("Socket conectado!");
    ensureSocketListeners();
    ensureAudienceSocketListeners();
    ensureEntitiesSocketListeners();
  });

  socket.on("disconnect", () => {
    console.log("Socket desconectado!");
  });

  // Adicione outros listeners se necessário
  return socket;
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    console.log("Socket foi desconectado.");
    socket = null;
  } else {
    console.warn("Nenhum socket para desconectar.");
  }
};

export const getSocket = () => {
  if (!socket) {
    console.warn("Socket não está conectado.");
  }
  return socket;
};
