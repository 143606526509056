<template>
  <div>
    <a :href="data.href" :target="data.target || '_blank'">{{ data.label }}</a>
  </div>
</template>

<script>
export default {
  name: "Link",

  props: {
    data: {
      type: Array,
      required: true,
    },

    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
