export function validation(value) {
  const sqlInjectionPattern =
    /('|;|--|\*|union|select|insert|drop|update|delete|execute|declare|alert|xp_|[a-zA-Z0-9_]+\s*\(\s*select|drop\s*table)/i;
  const htmlInjectionPattern = /<[^>]+>/;
  const cssInjectionPattern = /([;{}<>])/;

  // Verifica SQL Injection
  if (sqlInjectionPattern.test(value)) return false;

  // Verifica HTML Injection
  if (htmlInjectionPattern.test(value)) return false;

  // Verifica CSS Injection
  if (cssInjectionPattern.test(value)) return false;

  // Se passar em todas as verificações
  return true;
}
